import { DEFAULT_DATE_LOCAL } from './constants';

export const toLocaleDateString = (date: Date): string => {
  return date.toLocaleDateString(DEFAULT_DATE_LOCAL, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const getDateDetails = (
  monthLabels: string[],
  currentDate?: string,
  alreadyLocaleDate = false, // needed to prevent converting already converted utc to local date, default locale date
): {
  day: number;
  month: string;
  year: number;
  hours: number;
  minutes: number;
} => {
  // case YYYY-MM-DD => already converted dates
  // case YYYY-MM-DDT00:00:00+00:00 => UTC dates
  // see https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#format_de_la_cha%C3%AEne_de_caract%C3%A8res
  const date = currentDate !== undefined ? new Date(currentDate) : new Date();

  const day = alreadyLocaleDate ? date.getUTCDate() : date.getDate();
  const month = alreadyLocaleDate
    ? monthLabels[date.getUTCMonth()]
    : monthLabels[date.getMonth()];
  const year = alreadyLocaleDate ? date.getUTCFullYear() : date.getFullYear();
  const hours = alreadyLocaleDate ? date.getUTCHours() : date.getHours();
  const minutes = alreadyLocaleDate ? date.getUTCMinutes() : date.getMinutes();

  return { day, month, year, hours, minutes };
};

export const getCurrentDayBy10minutesStep = (
  monthLabels: string[],
  alreadyLocalDate = false,
): string => {
  const { day, month, year, hours, minutes } = getDateDetails(
    monthLabels,
    undefined,
    alreadyLocalDate,
  );

  // Les mois sont de 0 à 11, donc ajoutez 1 au mois référencé dans i18n
  const monthNumber = monthLabels.findIndex((mo) => mo === month) + 1;

  // Gérez le cas où les minutes arrondies atteignent 60
  const {
    adjustedYear,
    adjustedMonth,
    adjustedDay,
    adjustedHours,
    adjustedMinutes,
  } = adjustDateWith60mnCeiling(year, monthNumber, day, hours, minutes);

  // Formatez les heures et minutes pour afficher 2 chiffres
  const formattedHours = adjustedHours.toString().padStart(2, '0');
  const formattedMinutes = adjustedMinutes.toString().padStart(2, '0');

  // Combinez la date et l'heure arrondie
  return `${adjustedYear}/${adjustedMonth}/${adjustedDay} ${formattedHours}:${formattedMinutes}`;
};

export const adjustDateWith60mnCeiling = (
  year: number,
  month: number,
  day: number,
  hours: number,
  minutes: number,
): {
  adjustedYear: number;
  adjustedMonth: number;
  adjustedDay: number;
  adjustedHours: number;
  adjustedMinutes: number;
} => {
  // Arrondissez les minutes à la dizaine supérieure
  let adjustedMinutes = Math.ceil(minutes / 10) * 10;
  // Gérez le cas où les minutes arrondies atteignent 60
  let adjustedHours = hours;
  let adjustedDay = day;
  let adjustedMonth = month;
  let adjustedYear = year;

  if (adjustedMinutes === 60) {
    adjustedMinutes = 0;
    adjustedHours = (hours + 1) % 24; // Passer à l'heure suivante
    // Ajustement des jours si nécessaire
    if (adjustedHours === 0) {
      adjustedDay++;
      // Puis ajustement du mois si nécessaire
      if (adjustedDay > getDaysInMonth(month, year)) {
        adjustedDay = 1;
        // On incrément les mois uniquement si on n'est pas au mois de décembre
        if (adjustedMonth < 11) {
          adjustedMonth++;
        } else {
          // On passe au mois de janvier
          adjustedMonth = 0;
          // et On ajuste l'année
          adjustedYear++;
        }
      }
    }
  }
  return {
    adjustedYear,
    adjustedMonth,
    adjustedDay,
    adjustedHours,
    adjustedMinutes,
  };
};

export const getDaysInMonth = (month: number, year: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

export const getDateAtStartOfTheDay = (date: Date = new Date()): Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};
